<template>
  <div v-if="$can('product_list', 'product')">
    <v-card>
      <v-form ref="searchForm" @submit.prevent="toSearch()">
        <v-card-text class="d-flex flex-row-reverse pb-0">
          <div v-if="$can('product_save', 'product')" class="d-flex align-center">
            <v-btn color="primary" class="me-3" @click="editItem()">
              <span>新建商品</span>
            </v-btn>
          </div>

          <div class="d-flex align-center">
            <v-btn class="me-3" @click="resetSearch()">
              <span>重置</span>
            </v-btn>
          </div>
          <div class="d-flex align-center">
            <v-btn color="secondary" class="me-3" type="submit">
              <v-icon size="18" class="me-1">
                {{ icons.mdiMagnify }}
              </v-icon>
              <span>查询</span>
            </v-btn>
          </div>

          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <v-text-field v-model="search.name" outlined dense label="搜索商品"></v-text-field>
          </div>

          <div v-if="$can('product_list_search', 'product')" class="d-flex align-center pt-6 mr-5 pb-0">
            <v-select
              id="id"
              v-model="search.saleType"
              outlined
              dense
              label="销售类型"
              item-text="name"
              item-value="value"
              :items="saleTypes"
            ></v-select>
          </div>
          <div v-if="$can('product_list_search', 'product')" class="d-flex align-center pt-6 mr-5 pb-0">
            <v-select
              id="id"
              v-model="search.categoryId"
              outlined
              dense
              label="所属分类"
              item-text="name"
              item-value="id"
              :items="categorys"
            ></v-select>
          </div>
          <div v-if="$can('product_list_search', 'product')" class="d-flex align-center pt-6 mr-5 pb-0">
            <v-select
              id="productAttrId"
              v-model="search.productAttrId"
              outlined
              dense
              label="商品导航"
              item-text="name"
              item-value="productAttrId"
              :items="productAttrs"
            ></v-select>
          </div>
          <div v-if="$can('product_list_search', 'product')" class="d-flex align-center pt-6 mr-5 pb-0">
            <v-select
              id="id"
              v-model="search.warehouseId"
              outlined
              dense
              label="所属仓库"
              item-text="name"
              item-value="id"
              :items="warehouses"
            ></v-select>
          </div>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card class="mt-2">
      <v-card-title class="">
        <v-tabs v-model="currentTab" style="box-shadow: none !important" @change="reloadTab">
          <v-tab>出售中</v-tab>
          <v-tab>已下架</v-tab>
          <v-tab>全部商品</v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true,
          }"
          disable-filtering
          disable-sort
          :headers="getHeader()"
          :items="products"
          :page="pages.page"
          :items-per-page="pages.itemsPerPage"
          :server-items-length="pages.serverItemsLength"
          no-data-text="无数据"
          :loading="loadings.tableloading"
          loading-text="数据加载中..."
          @pagination="loadPage"
        >
          <template #[`item.productName`]="{ item }">
            <v-row>
              <v-col cols="auto">
                <v-avatar size="33" @click.stop="previewImg(item.productImg)">
                  <img :src="item.productImg || require('@/assets/images/white.jpeg')" alt="" />
                </v-avatar>
              </v-col>
              <v-col class="pl-0">
                <span class="text-subtitle-2">{{ item.productName }} </span><br />
                <span v-if="!item.isSingle" class="text-caption">多规格</span>
              </v-col>
            </v-row>
          </template>

          <template #[`item.price`]="{ item }">
            <span>￥{{ item.price }}</span>
          </template>

          <template #[`item.status`]="{ item }">
            <v-chip v-if="item.status === 0" small class="font-weight-semibold text-capitalize"> 已下架 </v-chip>
            <v-chip v-else-if="item.status === 1" color="primary" small class="font-weight-semibold text-capitalize">
              销售中
            </v-chip>
          </template>

          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <v-btn
              v-if="$can('product_toggle', 'product')"
              text
              dense
              x-small
              color="primary"
              @click="toggleEnabled(item)"
            >
              {{ item.status ? '下架' : '上架' }}
            </v-btn>
            <v-btn v-if="$can('product_update', 'product')" text dense x-small color="primary" @click="editItem(item)">
              编辑
            </v-btn>
            <v-btn
              v-if="$can('product_delete', 'product')"
              text
              dense
              x-small
              color="primary"
              @click="showDeleteDialog(item)"
            >
              删除
            </v-btn>
          </template>
        </v-data-table>
        <div style="position: absolute; right: 260px; bottom: 25px">
          <div style="display: flex; flex-wrap: wrap">
            <span style="padding: 7px; font-size: 13px">总页数：{{ pages.pages }}</span>
            <span style="padding: 7px; font-size: 13px">前往</span>
            <div style="width: 45px">
              <v-text-field
                @change="changePage"
                v-model="toPage"
                type="number"
                hide-details
                dense
                min="1"
              ></v-text-field>
            </div>
            <span style="padding: 7px; font-size: 13px">页</span>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="previewImgDialog" max-width="500">
      <v-card>
        <v-card-text>
          <v-img :src="previewImgUrl || require('@/assets/images/white.jpeg')" max-width="500"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? '80%' : '100%'"
      height="100%"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}商品</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-5" :disabled="commitFlag" :loading="commitFlag" @click="showSubmitDialog()">
            保存
          </v-btn>
          <v-btn icon small @click="editDialog = false">
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text style="background-color: #f4f5fa; height: 90%; padding-top: 20px" class="overflow-auto">
          <v-form ref="editForm" lazy-validation @submit.prevent="handleFormSubmit">
            <v-card>
              <v-card-title>
                <p class="text-h6">基本属性</p>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-file-input
                      v-model="currentImg"
                      :loadings="loadings.bannerUploaderLoading"
                      :disabled="loadings.bannerUploaderLoading"
                      label="上传图片"
                      dense
                      outlined
                      hide-details
                      @change="uploadImage(spu, currentImg, 'productImg')"
                    ></v-file-input>
                    <span class="text-caption mt-2 ml-3">建议尺寸：800*800像素</span>
                    <v-img
                      v-if="spu.productImg"
                      :src="spu.productImg"
                      class="mt-1 ml-3"
                      max-height="250"
                      max-width="250"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-checkbox v-model="spu.isIndex" label="首页显示" dense hide-details></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="spu.productName"
                      label="标题*"
                      outlined
                      :rules="rules.productName"
                      counter="200"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="spu.shareDescription"
                      label="分享描述"
                      outlined
                      counter="32"
                      dense
                      hide-details
                    ></v-text-field>
                    <span class="text-caption mt-3 ml-3">微信分享给好友时会显示，建议32个字以内</span>
                  </v-col>

                  <v-col cols="12" md="5" sm="6">
                    <v-select
                      v-if="spu.categoryId2"
                      id="id"
                      v-model="spu.categoryId2"
                      outlined
                      :rules="rules.required"
                      dense
                      label="商品分类*"
                      item-text="name"
                      item-value="id"
                      :items="categorys"
                      @change="checkCategory"
                    ></v-select>
                    <v-select
                      v-else
                      id="id"
                      v-model="spu.categoryId1"
                      outlined
                      :rules="rules.required"
                      dense
                      label="商品分类*"
                      item-text="name"
                      item-value="id"
                      :items="categorys"
                      @change="checkCategory"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="1" sm="2">
                    <v-btn
                      :disabled="!$can('product_save_update_add_category', 'product')"
                      text
                      color="primary"
                      @click="addCategoryDialog = true"
                    >
                      新建
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="5" sm="6">
                    <v-select
                      id="productAttrId"
                      v-model="spu.productAttrIds"
                      outlined
                      multiple
                      dense
                      label="商品导航"
                      item-text="name"
                      item-value="productAttrId"
                      :items="productAttrs"
                      hide-details
                    ></v-select>
                    <span class="text-caption mt-2 ml-3"> 首页快捷入口 </span>
                  </v-col>
                  <v-col cols="12" md="1" sm="2">
                    <v-btn
                      :disabled="!$can('product_save_update_add_attr', 'product')"
                      text
                      color="primary"
                      @click="addAttrDialog = true"
                    >
                      新建
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mt-7">
              <v-card-title>
                <p class="text-h6">规格/价格</p>
                <v-tabs v-model="formTab" style="box-shadow: none !important" lazy>
                  <v-tab @click="changeTab(0)"> 统一规格 </v-tab>
                  <v-tab @click="changeTab(1)"> 多规格 </v-tab>
                </v-tabs>
              </v-card-title>
              <v-card-text>
                <v-tabs-items v-model="formTab">
                  <v-tab-item>
                    <v-row v-if="formTab === 0" class="mt-5">
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="singleSku.price"
                          label="价格*"
                          outlined
                          type="number"
                          :rules="rules.price"
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="singleSku.internalCode"
                          label="内部编码"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                        <span class="text-caption mt-2 ml-3"> 用于内部管理、和外部对接使用 </span>
                      </v-col>
                      <!-- <v-col
                        cols="12"
                        md="4"
                      >
                        <v-select
                          id="id"
                          v-model="singleSku.warehouseId"
                          :rules="rules.required"
                          outlined
                          dense
                          label="所属仓库"
                          item-text="name"
                          item-value="id"
                          :items="warehouses"
                        ></v-select>
                      </v-col> -->
                    </v-row>
                    <v-row>
                      <v-col md="1">
                        <label for="name" class="text-subtitle-1">购买限制</label>
                      </v-col>

                      <v-col md="3">
                        <v-radio-group v-model="singleSku.isLimit" style="margin-top: -1px" row>
                          <v-radio label="不限制" :value="false" dense></v-radio>
                          <v-radio label="起订量" :value="true" dense></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                          id="moq"
                          v-model="singleSku.moq"
                          style="margin-top: -6px"
                          :rules="singleSku.isLimit ? rules.required : {}"
                          label="请输入起订量"
                          outlined
                          clearable
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col md="1">
                        <label for="name" class="text-subtitle-1">销售类型</label>
                      </v-col>

                      <v-col md="2">
                        <v-checkbox
                          v-model="singleSku.isInsideSale"
                          style="margin-top: -2px"
                          label="内部销售"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col md="2">
                        <v-checkbox
                          v-model="singleSku.isOutsideSale"
                          style="margin-top: -2px"
                          label="外部销售"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col md="3">
                        <v-checkbox
                          v-model="singleSku.allowNegativeSelling"
                          label="允许负卖"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col md="3">
                        <v-checkbox v-model="singleSku.showStockNum" label="显示库存" dense hide-details></v-checkbox>
                      </v-col>
                      <v-col md="3">
                        <v-checkbox v-model="singleSku.status" label="出售中" dense hide-details></v-checkbox>
                      </v-col>
                      <v-col md="3">
                        <v-checkbox
                          v-model="singleSku.useMemberPrice"
                          label="启用会员价"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row v-if="singleSku.useMemberPrice">
                      <v-col>
                        <v-simple-table fixed-header height="300px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">会员等级</th>
                                <th class="text-left">折扣</th>
                                <th class="text-left">价格设置</th>
                                <th class="text-left">价格</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in singleSku.members" :key="index">
                                <td>{{ item.levelName }}</td>
                                <td>{{ item.discount }}</td>
                                <td>
                                  <v-switch
                                    v-model="item.isMemberPrice"
                                    dense
                                    x-small
                                    :label="item.isMemberPrice ? `按折扣` : `手动`"
                                  ></v-switch>
                                </td>
                                <td>
                                  <span v-if="item.isMemberPrice"
                                    >￥{{ parseFloat(singleSku.price * (item.discount / 100)).toFixed(2) }}</span
                                  >
                                  <span v-else>
                                    <v-text-field
                                      v-if="formTab === 0"
                                      v-model="item.diyPrice"
                                      style="width: 150px"
                                      :rules="rules.required"
                                      label="价格*"
                                      type="number"
                                      dense
                                      hide-details
                                    ></v-text-field>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row v-if="formTab === 1" class="mt-5">
                      <v-col>
                        <v-row>
                          <v-col><span>&nbsp;Tips: 输入单个参数后，需回车才能生成对应记录.</span></v-col>
                        </v-row>
                        <v-row v-for="(multiple, index) in multiples" :key="index">
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="multiple.name"
                              label="规格*"
                              :rules="rules.required"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="5">
                            <v-combobox
                              v-model="multiple.value"
                              label="参数*"
                              :items="multiple.value"
                              outlined
                              dense
                              multiple
                              @change="renderMultiple(multiple)"
                            ></v-combobox>
                          </v-col>
                          <v-col cols="12" md="1">
                            <v-btn color="success" @click="modifyAttr(index)"> 修改 </v-btn>
                          </v-col>
                          <v-col cols="12" md="1">
                            <v-btn color="error" @click="deleteMulit(multiple)"> 删除 </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn color="secondary" @click="addMulit"> 添加规格 </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col v-for="(sku, index) in skus" :key="index" md="12">
                        <div v-if="formTab === 1" class="box">
                          <v-row>
                            <v-col md="12">
                              <span class="text-subtitle-1">{{ getTitle(sku.indexes) }}</span>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-file-input
                                v-model="currentImg"
                                :loadings="loadings.bannerUploaderLoading"
                                :disabled="loadings.bannerUploaderLoading"
                                label="上传图片"
                                dense
                                outlined
                                hide-details
                                @change="uploadImage(sku, currentImg, 'skuProductImg')"
                              ></v-file-input>
                              <span class="text-caption mt-2 ml-3">建议尺寸：800*800像素</span>
                              <v-img
                                v-if="sku.skuProductImg"
                                :src="sku.skuProductImg"
                                class="mt-1 ml-3"
                                max-height="250"
                                max-width="250"
                              ></v-img>
                            </v-col>
                          </v-row>
                          <v-row class="mt-5">
                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="sku.price"
                                :rules="rules.price"
                                label="价格*"
                                outlined
                                type="number"
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="sku.internalCode"
                                label="内部编码"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                              <span class="text-caption mt-2 ml-3"> 用于内部管理、和外部对接使用 </span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col md="1">
                              <label for="name" class="text-subtitle-1">购买限制</label>
                            </v-col>

                            <v-col md="3">
                              <v-radio-group v-model="sku.isLimit" style="margin-top: -2px" row>
                                <v-radio label="不限制" :value="false" dense></v-radio>
                                <v-radio label="起订量" :value="true" dense></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col md="3">
                              <v-text-field
                                id="moq"
                                v-model="sku.moq"
                                style="margin-top: -2px"
                                :rules="sku.isLimit ? rules.required : {}"
                                label="请输入起订量"
                                outlined
                                clearable
                                dense
                                type="number"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col md="1">
                              <label for="name" class="text-subtitle-1">销售类型</label>
                            </v-col>

                            <v-col md="2">
                              <v-checkbox
                                v-model="sku.isInsideSale"
                                style="margin-top: -2px"
                                label="内部销售"
                                dense
                                hide-details
                              ></v-checkbox>
                            </v-col>
                            <v-col md="2">
                              <v-checkbox
                                v-model="sku.isOutsideSale"
                                style="margin-top: -2px"
                                label="外部销售"
                                dense
                                hide-details
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0">
                            <v-col md="3">
                              <v-checkbox
                                v-model="sku.allowNegativeSelling"
                                label="允许负卖"
                                dense
                                hide-details
                              ></v-checkbox>
                            </v-col>
                            <v-col md="3">
                              <v-checkbox v-model="sku.showStockNum" label="显示库存" dense hide-details></v-checkbox>
                            </v-col>
                            <v-col md="3">
                              <v-checkbox v-model="sku.status" label="出售中" dense hide-details></v-checkbox>
                            </v-col>
                            <v-col md="3">
                              <v-checkbox
                                v-model="sku.useMemberPrice"
                                label="启用会员价"
                                dense
                                hide-details
                              ></v-checkbox>
                            </v-col>
                          </v-row>

                          <v-row v-if="sku.useMemberPrice">
                            <v-col>
                              <v-simple-table fixed-header height="300px">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">会员等级</th>
                                      <th class="text-left">折扣</th>
                                      <th class="text-left">价格设置</th>
                                      <th class="text-left">价格</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item, index) in sku.members" :key="index">
                                      <td>{{ item.levelName }}</td>
                                      <td>{{ item.discount }}</td>
                                      <td>
                                        <v-switch
                                          v-model="item.isMemberPrice"
                                          dense
                                          x-small
                                          :label="item.isMemberPrice ? `按折扣` : `手动`"
                                        ></v-switch>
                                      </td>
                                      <td>
                                        <span v-if="item.isMemberPrice"
                                          >￥{{ parseFloat(sku.price * (item.discount / 100)).toFixed(2) }}</span
                                        >
                                        <span v-else>
                                          <v-text-field
                                            v-model="item.diyPrice"
                                            :rules="rules.required"
                                            style="width: 150px"
                                            label="价格*"
                                            type="number"
                                            dense
                                            hide-details
                                          ></v-text-field>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>

            <v-card class="mt-7">
              <v-card-title>
                <p class="text-h6">图文详情</p>
              </v-card-title>
              <v-card-text>
                <ckeditor v-model="editorData" :editor="editor" :config="editorConfig"></ckeditor>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card-text>
      </v-card>
      <v-dialog> </v-dialog>
      <v-dialog v-model="submitDialog" width="300">
        <v-card>
          <v-card-text>
            是否确定使用
            <span style="color: red">{{ formTab === 0 ? '统一规格' : '多规格' }}</span>
            的方式进行保存?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="handleFormSubmit()"> 确认保存 </v-btn>
            <v-btn color="normal" text @click="submitDialog = false"> 取消 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addCategoryDialog" width="750">
        <v-card>
          <v-card-title>新增商品分类</v-card-title>
          <v-card-text>
            <v-form ref="categoryForm" lazy-validation @submit.prevent="saveCategory">
              <v-container>
                <v-row>
                  <v-col cols="12" md="2">
                    <label for="name"><span style="color: red">*</span>分类名称：</label>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      id="name"
                      v-model="category.name"
                      :rules="rules.required"
                      required
                      counter="25"
                      label="商品分类名称"
                      outlined
                      clearable
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <label for="parentName">上级分类：</label>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-select
                      id="id"
                      v-model="category.parentId"
                      outlined
                      dense
                      label="商品分类*"
                      item-text="name"
                      item-value="id"
                      :items="categorys"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="2">
                    <label for="orderNum">排序：</label>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      id="orderNum"
                      v-model="category.sort"
                      label="排序"
                      type="Number"
                      outlined
                      clearable
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <label for="description"><span style="color: red">*</span>描述：</label>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-textarea
                      v-model="category.description"
                      :rules="rules.required"
                      outlined
                      name="input-7-4"
                      label="描述"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="normal" text @click="addCategoryDialog = false"> 关闭 </v-btn>
            <v-btn color="primary" text @click="saveCategory()"> 保存 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addAttrDialog" width="750">
        <v-card>
          <v-card-title>新增商品导航</v-card-title>
          <v-card-text>
            <v-form ref="attrForm" lazy-validation @submit.prevent="saveAttr">
              <v-container>
                <v-row>
                  <v-col cols="12" md="2">
                    <label for="name"><span style="color: red">*</span>属性名称：</label>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      id="name"
                      v-model="attr.name"
                      :rules="rules.required"
                      required
                      counter="25"
                      label="商品导航名称"
                      outlined
                      clearable
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="2">
                    <label for="orderNum">排序：</label>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      id="orderNum"
                      v-model="attr.sort"
                      label="排序"
                      type="Number"
                      outlined
                      clearable
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="normal" text @click="addAttrDialog = false"> 关闭 </v-btn>
            <v-btn color="primary" text @click="saveAttr()"> 保存 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modifyAttrDialog" scrollable width="750">
        <v-card v-if="multiples.length > 0 && (modifyAttrIndex || modifyAttrIndex === 0)">
          <v-card-title>{{ multiples[modifyAttrIndex].name }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" v-for="item in multiples[modifyAttrIndex].value.length" :key="item">
                <v-text-field v-model="multiples[modifyAttrIndex].value[item - 1]" dense></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="modifyAttrDialog = false"> 确认 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
    <v-dialog v-model="deleteDialog" width="300">
      <v-card>
        <v-card-text>
          确定删除商品:<span style="color: red">{{ deleteData.productName }} </span>?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteItem()"> 确认删除 </v-btn>
          <v-btn color="normal" text @click="deleteDialog = false"> 取消 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay"> </v-overlay>
  </div>
</template>

<script>
import { mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiClose } from '@mdi/js'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ProductApi from '@/api/product/product'
import CategoryApi from '@/api/product/category'
import ProductAttrApi from '@/api/product/productAttr'
import uploadImg from '@/api/upload'
import { MyCustomUploadAdapterPlugin } from '../../plugins/upload-adapter'
import PreviewImg from '@/components/PreViewImg'

export default {
  name: 'Product',
  components: {
    PreviewImg,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
      },
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
        language: 'zh-cn',
        toolbar: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'uploadImage', 'undo', 'redo'],
        extraPlugins: [MyCustomUploadAdapterPlugin],
      },
      editDialog: false,
      deleteDialog: false,
      submitDialog: false,
      addCategoryDialog: false,
      addAttrDialog: false,
      treeDialog: false,
      rules: {
        productName: [v => !!v || '内容不能为空', v => (v && v.length <= 200) || '内容长度不能超过200'],
        required: [v => !!v || '内容不能为空'],
        price: [v => v >= 0 || '价格不能小于0'],
        counter: [v => (v && v.length <= 32) || '内容长度不能超过32'],
        stockNum: [v => v >= 0 || '库存数量不能小于0'],
        img: [value => !value || value.size < 2 * 1024 * 1024 || '图片大小不能超过2M'],
      },
      loadings: {
        submitLoading: false,
        bannerUploaderLoading: false,
        tableloading: false,
      },
      products: [],
      categorys: [],
      productAttrs: [],
      members: [],
      spu: {
        productImg: '',
        isIndex: false,
      },
      skus: [],
      singleSku: {
        price: 0,
        saleType: '',
        allowNegativeSelling: true,
        showStockNum: true,
        useMemberPrice: true,
        status: true,
        isInsideSale: false,
        isOutsideSale: false,
        isLimit: false,
        members: [],
        moq: 0,
      },
      defaultSku: {
        stockNum: 0,
        price: 0,
        saleType: '',
        internalCode: '',
        allowNegativeSelling: true,
        showStockNum: true,
        useMemberPrice: true,
        isInsideSale: false,
        isOutsideSale: false,
        isLimit: false,
        status: true,
        moq: 0,
      },
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
        pages: 0,
      },
      editedItem: {},
      editedIndex: -1,
      needRemoveSkuId: [],
      deleteData: {},
      search: {},
      status: 1,
      currentTab: 0,
      formTab: 0, // 0: 统一规格、 1: 多规格
      checkboxOne: true,
      enableMember: true,
      price: 0,
      sourceMultiples: [],
      multiples: [],
      currentImg: {},
      category: {
        sort: 1,
      },
      attr: {
        sort: 1,
      },
      warehouses: [],
      saleTypes: [
        {
          name: '对外销售',
          value: 'OUTSIDE_SALE',
        },
        {
          name: '对内销售',
          value: 'INSIDE_SALE',
        },
      ],
      overlay: false,
      commitFlag: false,
      previewImgUrl: '',
      previewImgDialog: false,
      modifyAttrDialog: false,
      modifyAttrIndex: undefined,
      toPage: null,
    }
  },
  mounted() {
    this.loadCategoryAndProductAttrAndWarehouse()
  },
  computed: {
    getMultiples() {
      if (this.modifyAttrIndex === undefined) {
        return []
      }
      return this.multiples[0].value
    },
  },
  methods: {
    loadCategoryAndProductAttrAndWarehouse() {
      if (this.$can('product_list_search', 'product') || this.$can('product_save_update_category_attr', 'product')) {
        ProductApi.getSearchList().then(res => {
          const { categorys, attrs, warehouses, members } = res.data.data

          this.productAttrs = attrs
          categorys.forEach(category => {
            if (category.parentId !== 0) {
              category.name = `\xa0\xa0${category.name}`
            }
          })
          this.categorys = categorys
          this.warehouses = warehouses

          members.forEach(item => {
            item.isMemberPrice = true
            item.memberLevelId = item.id
          })
          this.members = members
        })
      }
    },
    getHeader() {
      const baseHeader = [
        // { text: '#ID', value: 'id' },
        { text: '商品名称', value: 'productName' },
        { text: '库存', value: 'stockNum' },
        { text: '价格', value: 'price' },
        { text: '所属分类', value: 'categoryName' },
        { text: '内部编码', value: 'internalCode' },
        { text: '创建时间', value: 'createdTime' },
        { text: '状态', value: 'status' },
      ]

      if (
        this.$can('product_update', 'product') ||
        this.$can('product_toggle', 'product') ||
        this.$can('product_delete', 'product')
      ) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSearch() {
      this.toPage = null
      this.pages.page = 1
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    changePage(modifyPage) {
      if (modifyPage > this.pages.pages) {
        this.$toast.error('超过最大总页数')
        return
      }
      this.pages.page = parseInt(modifyPage)
    },
    loadPage({ page, itemsPerPage }) {
      this.loadings.tableloading = true
      if (this.$route.query.productAttrId) {
        this.search.productAttrId = parseInt(this.$route.query.productAttrId, 0)
        this.$route.query.productAttrId = null
      }
      if (this.$route.query.categoryId) {
        this.search.categoryId = parseInt(this.$route.query.categoryId, 0)
        this.$route.query.categoryId = null
      }
      if (this.$route.query.warehouseId) {
        this.search.warehouseId = parseInt(this.$route.query.warehouseId, 0)
        this.$route.query.warehouseId = null
      }

      this.pages.page = page
      this.pages.itemsPerPage = itemsPerPage
      const searchData = {
        rows: itemsPerPage,
        page,
        categoryId: this.search.categoryId,
        attrId: this.search.productAttrId,
        warehouseId: this.search.warehouseId,
        name: this.search.name,
        status: this.status,
      }

      if (this.search.saleType) {
        const type = this.search.saleType
        if (type === 'OUTSIDE_SALE') {
          searchData.isOutsideSale = true
        } else {
          searchData.isInsideSale = true
        }
      }
      ProductApi.getPages(searchData)
        .then(response => {
          this.loadings.tableloading = false
          const { data } = response.data
          this.products = data.records
          this.pages.pages = data.pages
          if (data.size === -1) {
            this.pages.serverItemsLength = this.products.length
          } else {
            this.pages.serverItemsLength = data.total
          }
        })
        .catch(error => {
          this.loadings.tableloading = false
        })
    },
    uploadImg(blob, callback) {
      // 发送请求上传到七牛云，将图片地址返回到这里
      uploadImg.uploadImg(blob).then(res => {
        callback(res.data.data, 'img')
      })
    },
    addMulit() {
      this.multiples.push({
        name: '',
        value: [],
      })

      this.sourceMultiples.push({
        name: '',
        value: [],
      })
    },
    findNeedDeleteIndex(updateValue, sourceValue) {
      // 假设删除最后一个
      let deleteIndex = sourceValue.length - 1
      // 遍历，如果源数据和更新数据的下标值不一致，说明删除下标是当前下标
      for (let index = 0; index < sourceValue.length; index++) {
        if (updateValue[index] !== sourceValue[index]) {
          deleteIndex = index
          break
        }
      }

      return deleteIndex
    },
    removeSku(row, col) {
      const needRemoveSkuId = []
      let currentRow = row
      for (let i = 0; i < this.multiples.length; i++) {
        if (i === row) {
          break
        }
        if (this.multiples[i].value.length === 0) {
          currentRow -= 1
        }
      }
      this.skus = this.skus.filter(sku => {
        const temp = sku.indexes.split('-')
        if (parseInt(temp[currentRow]) === col) {
          if (sku.skuId) {
            needRemoveSkuId.push(sku.skuId)
          }

          return false // 返回 false 从数组中过滤掉当前对象
        }

        return true // 返回 true 保留当前对象在数组中
      })

      // 下标往前移动
      if (this.skus.length > 0) {
        this.skus.forEach(sku => {
          const temp = sku.indexes.split('-')
          let currentIndex = parseInt(temp[currentRow])
          if (currentIndex > col) {
            currentIndex -= 1
            temp[currentRow] = currentIndex
          }
          const newIndexes = temp.join('-')
          sku.indexes = newIndexes
        })
      }

      return needRemoveSkuId
    },
    reloadIndexesAndTitle(row, col) {
      const newTitleAndIndexes = this.getCombinations()
      if (newTitleAndIndexes.length === 1 && this.skus.length === 1) {
        this.needRemoveSkuId.push(this.skus[0].skuId)
        this.skus.splice(0, 1)

        return
      }

      let currentRow = row
      for (let i = 0; i < this.multiples.length; i++) {
        if (i === row) {
          break
        }
        if (this.multiples[i].value.length === 0) {
          currentRow -= 1
        }
      }

      if (newTitleAndIndexes.length > 0 && this.skus.length > 0) {
        for (let i = 0; i < newTitleAndIndexes.length; i++) {
          const temp = newTitleAndIndexes[i]
          const newIndex = temp.indexes
          const indexes = temp.indexes.split('-')

          indexes.splice(currentRow, 0, col)
          const indexesStr = indexes.join('-')
          for (let j = 0; j < this.skus.length; j++) {
            const sku = this.skus[j]
            if (sku.indexes.startsWith(indexesStr)) {
              sku.title = temp.value
              sku.indexes = newIndex
              break
            }
          }
        }
      }
    },
    horizontalAdd(temp) {
      // 过滤出新生成的下标和标题 不在sku里面的
      const uniqueSku = temp.filter(item => {
        const { indexes } = item
        const { value } = item

        return !this.skus.some(sku => sku.indexes === indexes && sku.title === value)
      })
      // 遍历不在sku里面的新标题和下标 然后插入到skus中
      uniqueSku.forEach(item => {
        const newSku = this.getSkuTemplate()
        newSku.title = item.value
        newSku.indexes = item.indexes
        this.skus.push(newSku)
      })
    },
    verticalAdd(row, temp) {
      // temp 为新生成的下标和标题
      if (this.skus.length === 0) {
        const newSku = this.getSkuTemplate()
        newSku.title = temp[0].value
        newSku.indexes = temp[0].indexes
        this.skus.push(newSku)

        return
      }
      // 对每一个temp进行遍历
      for (let i = 0; i < temp.length; i++) {
        // 获取当前temp
        const obj = temp[i]
        // 获取新生成的下标
        const newIndex = obj.indexes
        // 遍历当前的sku， 查找出对应位置的sku进行替换下标和标题
        for (let j = 0; j < this.skus.length; j++) {
          const sku = this.skus[j]
          // 想将下标值转换为数组
          const indexes = sku.indexes.split('-')
          // 再将当前行的下标插入到数组中，形成新的下标
          indexes.splice(row, 0, 0)
          // 如果当前sku的下标以新生成的下标开头，则进行替换
          const indexesStr = indexes.join('-')
          if (indexesStr.startsWith(newIndex)) {
            sku.title = obj.value
            sku.indexes = newIndex
            break
          }
        }
      }
    },
    deleteMulit(multiple) {
      if (multiple.value.length > 0) {
        this.$toast.warning('规格参数值不为空,不能删除,请取消选中参数后再进行删除')

        return
      }

      // 当前节点移除
      const index = this.multiples.indexOf(multiple)
      this.multiples.splice(index, 1)
      this.sourceMultiples.splice(index, 1)
    },
    renderMultiple(val) {
      const row = this.multiples.indexOf(val)
      const updateValueLength = val.value.length
      const updateValue = val.value
      const sourceValueLength = this.sourceMultiples[row].value.length
      const sourceValue = this.sourceMultiples[row].value

      // 移除操作
      if (sourceValueLength > updateValueLength) {
        const col = this.findNeedDeleteIndex(updateValue, sourceValue)
        if (updateValueLength > 0) {
          const needRemoveSkuId = this.removeSku(row, col)
          needRemoveSkuId.forEach(item => {
            this.needRemoveSkuId.push(item)
          })
        }

        // 更新title 和 indexes
        if (updateValueLength === 0) {
          this.reloadIndexesAndTitle(row, col)
        }
        this.sourceMultiples[row].value = this.multiples[row].value
      } else {
        // 新增操作
        const temp = this.getCombinations()
        if (sourceValueLength > 0) {
          // 横向扩展
          this.horizontalAdd(temp)
        } else {
          // 纵向扩展
          this.verticalAdd(row, temp)
        }
        // 更新完后记录当前的值
        this.sourceMultiples[row].value = this.multiples[row].value
      }
      this.skus = this.removeDuplicate(this.skus, 'indexes')
    },
    fillInMemberInformation(skus) {
      skus.forEach(sku => {
        // 遍历 array2 数组对象
        for (let i = 0; i < sku.members.length; i++) {
          const item2 = sku.members[i]
          const { memberLevelId } = item2

          // 在 array1 数组对象中查找相同 ID 的项
          const item1 = this.members.find(item => item.memberLevelId === memberLevelId)

          // 如果找到匹配的项，则将所需的信息填充到 array2 数组对象中
          if (item1) {
            item2.levelName = item1.levelName
            item2.discount = item1.discount
          }
        }
      })
    },
    editItem(item) {
      this.multiples = []
      this.sourceMultiples = []
      this.commitFlag = false
      if (item) {
        ProductApi.getProductDeatil(item.id).then(res => {
          const { spu, skus } = res.data.data
          this.spu = spu
          this.fillInMemberInformation(skus)
          const description = this.spu.description || ''
          this.$nextTick(() => {
            this.editorData = description
          })
          if (spu.isSingle) {
            this.formTab = 0
            this.singleSku = { ...skus[0] }
          } else {
            this.formTab = 1
            if (spu.specKey) {
              spu.specKey.forEach(attr => {
                const value = [...attr.value]
                const obj = {
                  name: attr.name,
                  value,
                }
                const sourceValue = [...attr.value]
                const sourceObj = {
                  name: attr.name,
                  value: sourceValue,
                }
                this.multiples.push(obj)
                this.sourceMultiples.push(sourceObj)
              })
            }

            this.skus = skus
          }
        })
      } else {
        this.skus = []
        this.formTab = 0
        this.spu = {}
        this.singleSku = { ...this.defaultSku }
        this.singleSku.members = JSON.parse(JSON.stringify(this.members))
        this.$nextTick(() => {
          this.editorData = ''
          this.$refs.editForm.resetValidation()
        })
      }

      this.$nextTick(() => {
        this.editDialog = true
      })
    },
    showSubmitDialog() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return
      this.submitDialog = true
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return

      if (this.commitFlag) {
        return
      }

      const isSingle = this.formTab === 0
      this.spu.isSingle = isSingle

      this.spu.description = this.editorData

      const saveData = {
        spu: this.spu,
      }
      if (saveData.spu.categoryId2 != null) {
        saveData.spu.categoryId1 = saveData.spu.categoryId2
        saveData.spu.categoryId2 = null
      }

      const checkFlag = this.checkCategory(saveData.spu.categoryId1)
      if (!checkFlag) {
        return
      }
      if (isSingle) {
        const skus = []
        skus.push(this.singleSku)
        saveData.skus = skus
      } else {
        saveData.skus = this.skus
        saveData.spu.specKey = this.multiples
        saveData.skus.forEach(sku => {
          sku.title = this.getTitle(sku.indexes)
        })

        // 检查sku的数量是否和规格属性数量一致
        let attrCount = 0
        for (var i = 0; i < saveData.spu.specKey.length; i++) {
          let item = saveData.spu.specKey[i]
          if (attrCount === 0) {
            attrCount = 1
          }
          attrCount = attrCount * item.value.length
        }

        // 多规格时必须有至少1个参数
        if (attrCount === 0) {
          this.$toast.warning('多规格时请填写至少一个规格-参数')
          return
        }

        if (attrCount !== saveData.skus.length) {
          this.$toast.warning('规格参数与具体sku存在不一致，请检查后重试')
          return
        }
      }

      // 限制处理
      saveData.skus.forEach(sku => {
        if (!sku.isLimit) {
          sku.moq = 0
        }
      })

      if (this.needRemoveSkuId.length > 0) {
        saveData.needRemoveSkuId = this.needRemoveSkuId
      }
      this.commitFlag = true

      ProductApi.saveOrUpdate(saveData).then(res => {
        this.$toast.success('保存成功！')
        this.skus = []
        this.loadPage({ page: this.pages.page, itemsPerPage: this.pages.itemsPerPage })
        this.editDialog = false
        this.commitFlag = false
        this.submitDialog = false
      })
    },
    showDeleteDialog(item) {
      this.deleteData = item
      this.editedIndex = this.products.indexOf(item)
      this.deleteDialog = true
    },
    deleteItem() {
      ProductApi.delete(this.deleteData.id).then(() => {
        this.$toast.success('删除成功！')
        this.products.splice(this.editedIndex, 1)
        this.deleteData = {}
        this.editedIndex = -1
        this.deleteDialog = false
      })
    },
    toggleEnabled(item) {
      let saveStatus
      if (item.status === 1) {
        saveStatus = 0
      } else {
        saveStatus = 1
      }
      const saveData = {
        id: item.id,
        status: saveStatus,
      }
      ProductApi.toggleEnabled(saveData).then(res => {
        this.$toast.success('修改成功！')
        this.toSearch()
      })
    },
    reloadTab() {
      if (this.currentTab === 2) {
        this.status = undefined
      } else if (this.currentTab === 1) {
        this.status = 0
      } else if (this.currentTab === 0) {
        this.status = 1
      }
      this.toSearch()
    },
    resetSearch() {
      this.$refs.searchForm.reset()
      this.pages.page = 1
      this.toPage = null
      this.pages.itemsPerPage = 15
      this.toSearch()
    },
    getCombinations() {
      const combinations = []
      this.generateCombinations(this.multiples, 0, [], combinations)

      return combinations
    },
    generateCombinations(multiples, currentIndex, currentCombination, combinations) {
      if (currentIndex === multiples.length) {
        const value = currentCombination.filter(Boolean).join('/')
        const indexes = currentCombination
          .map((value, index) => multiples[index].value.indexOf(value))
          .filter(index => index !== -1)
          .join('-')
        combinations.push({ value, indexes })

        return
      }

      const currentOption = multiples[currentIndex]

      if (currentOption.value.length === 0) {
        // 如果数组为空，则直接进入下一个选项
        this.generateCombinations(multiples, currentIndex + 1, currentCombination, combinations)

        return
      }

      for (let i = 0; i < currentOption.value.length; i++) {
        currentCombination[currentIndex] = currentOption.value[i]
        this.generateCombinations(multiples, currentIndex + 1, currentCombination, combinations)
      }
    },
    getSkuTemplate() {
      const template = {}
      template.skuId = undefined
      template.title = ''
      template.price = 0
      template.indexes = ''
      template.moq = 0
      template.isLimit = false
      template.saleType = ''
      template.stockNum = 0
      template.internalCode = ''
      template.skuProductImg = ''
      template.isInsideSale = false
      template.isOutsideSale = false
      template.allowNegativeSelling = true
      template.showStockNum = true
      template.useMemberPrice = true
      template.status = true
      template.members = JSON.parse(JSON.stringify(this.members))

      return template
    },
    uploadImage(item, img, key) {
      if (img) {
        this.loadings.bannerUploaderLoading = true

        uploadImg
          .uploadImg(img)
          .then(res => {
            this.$set(item, key, res.data.data)
            this.currentImg = {}
          })
          .finally(() => {
            this.loadings.bannerUploaderLoading = false
          })
      }
    },
    changeTab(val) {
      if (this.spu.id && this.spu.isSingle && val === 1 && this.skus.length === 0) {
        this.addMulit()
        this.singleSku.indexes = '0'
        this.skus.push(this.singleSku)
      }
      if (this.spu.id && !this.spu.isSingle && val === 0) {
        this.singleSku = this.defaultSku
        this.singleSku.members = JSON.parse(JSON.stringify(this.members))
      }
    },
    saveCategory() {
      const isFormValid = this.$refs.categoryForm.validate()
      if (!isFormValid) return
      if (!this.category.parentId) {
        this.category.parentId = 0
      }
      const saveData = {
        ...this.category,
      }
      CategoryApi.saveOrUpdate(saveData).then(res => {
        this.$toast.success('新增成功！')
        this.loadCategoryAndProductAttrAndWarehouse()
        this.category = {}
        this.$nextTick(() => {
          this.category.sort = 1
          this.$refs.categoryForm.resetValidation()
        })
        this.addCategoryDialog = false
      })
    },
    saveAttr() {
      const isFormValid = this.$refs.attrForm.validate()
      if (!isFormValid) return
      const saveData = {
        ...this.attr,
      }
      ProductAttrApi.saveOrUpdate(saveData).then(res => {
        this.$toast.success('新增成功！')
        this.loadCategoryAndProductAttrAndWarehouse()
        this.attr = {}
        this.$nextTick(() => {
          this.attr.sort = 1
          this.$refs.attrForm.resetValidation()
        })
        this.addAttrDialog = false
      })
    },
    checkCategory(item) {
      // 从categorys 查找当前item是否是一级分类，如果是一级分类，提示不可选
      const isTopCategory = this.categorys.some(category => category.id === item && category.parentId === 0)
      if (isTopCategory) {
        this.$toast.warning('请选择分类明细！')

        return false
      }

      return true
    },
    previewImg(url) {
      this.previewImgUrl = url
      this.previewImgDialog = true
    },
    modifyAttr(index) {
      this.modifyAttrDialog = true
      this.modifyAttrIndex = index
    },
    getTitle(indexes) {
      const temp = indexes.split('-')
      const title = []
      for (let i = 0; i < temp.length; i++) {
        const index = parseInt(temp[i])
        const value = this.multiples[i].value[index]
        title.push(value)
      }

      return title.join('/')
    },
    // 根据sku的indexes对列表进行去重
    removeDuplicate(array, key) {
      return array.filter((item, index, self) => index === self.findIndex(obj => obj[key] === item[key]))
    },
  },
}
</script>

<style>
.box {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  padding: 20px;
  border-radius: 10px;
}

.ck-content {
  min-height: 400px;
}
</style>
